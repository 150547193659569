<template>
  <div class="no-copy" @contextmenu.prevent="blockContextMenu">
    <PageLoader v-bind:storage="appLoading" />
    <v-snackbar v-model="snackbar" color="#3CB043" right :timeout="3000">
      <v-layout wrap>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="snackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <ServerError v-if="ServerError" />
    <v-dialog v-model="showDialog" max-width="490">
      <v-card>
        <v-card-title class="text-h5">
          Do you want to leave this page?
        </v-card-title>

        <v-card-text>
          Redirecting from this page will discard your changes.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="blue darken-1" text @click="closeDialog">
            Cancel
          </v-btn>

          <v-btn color="red darken-1" text @click="closeDialog"> Ok </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-layout wrap px-4 justify-center style="background-color: white">
      <v-flex xs12 sm11 py-10 px-5>
        <v-layout wrap justify-center>
          <v-flex xs12>
            <v-btn :ripple="false" depressed color="#9FD58C"
              ><span style="color: #0f0f0f; font-family: poppinsregular"
                >TEST-1</span
              ></v-btn
            >
          </v-flex>
          <v-flex xs12 pt-2>
            <span
              style="
                color: #000000;
                font-family: poppinsmedium;
                font-size: 18px;
              "
              >{{ course.courseName }}
            </span>
          </v-flex>
        </v-layout>
        <v-layout wrap py-5 justify-center>
          <v-flex xs12>
            <span
              style="
                color: #000000;
                font-family: poppinssemibold;
                font-size: 14px;
              "
              >Remaining Time</span
            >
            <br />
            <span
              style="
                color: #3cb043;
                font-family: opensanssemibold;
                font-size: 25px;
              "
            >
              {{ formattedTime }}</span
            >
            <br />
            <span
              style="
                color: #000000;
                font-family: poppinsregular;
                font-size: 13px;
              "
              >Hours &nbsp; &nbsp; Min &nbsp; &nbsp; Sec</span
            >
          </v-flex>
          <v-flex xs12 pt-6>
            <v-btn depressed :ripple="false" color="#3A3A3A" dark
              ><span style="font-family: opensanssemibold; text-transform: none"
                >Question {{ number }} of 60</span
              ></v-btn
            >
          </v-flex>
        </v-layout>
        <v-layout wrap v-if="section == 1 && section2 != 2">
          <v-flex xs12>
            <v-card outlined>
              <v-layout wrap justify-center py-10 v-if="currentQuestion">
                <v-flex xs11 sm10 text-left>
                  <span style="font-family: poppinsmedium">{{
                    currentQuestion.questionId.question
                  }}</span>
                </v-flex>
                <v-flex
                  xs12
                  sm10
                  pt-10
                  pa-2
                  text-left
                  v-for="(op, o) in currentQuestion.options"
                  :key="o"
                >
                  <v-card
                    :color="answer == op._id ? '#4c993f' : '#F1F1F1'"
                    :outlined="answer == op._id ? true : false"
                    style="cursor: pointer"
                    @click="storeAnswer(op, currentQuestion)"
                  >
                    <v-layout wrap justify-center pa-5>
                      <v-flex xs12 text-left>
                        <span
                          style="
                            font-family: poppinsregular;
                            text-transform: none;
                          "
                          :style="answer == op._id ? 'color:white' : 'color:#444444'"
                        >
                          <span style="font-family: poppinssemibold">
                            &nbsp;</span
                          >{{ op.option }}</span
                        >
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-card>
            <v-layout wrap py-10 v-if="answer">
              <v-flex>
                <v-btn tile color="#3CB043" dark @click="nextQuestionMCQ"
                  ><span
                    style="text-transform: none; font-family: opensanssemibold"
                    >Save and Continue</span
                  ></v-btn
                >
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout wrap v-if="section2 == 2 && section3 != 3">
          <v-flex xs12>
            <v-card outlined>
              <v-layout
                wrap
                justify-center
                py-10
                v-if="currentQuestionSection2"
              >
                <v-flex xs12 sm10 text-left>
                  <span style="font-family: poppinsmedium">{{
                    currentQuestionSection2.questionId.question
                  }}</span>
                </v-flex>
                <v-flex xs12 sm10 pt-10 pa-2 text-left>
                      <span @click="
                            storeAnswerTrueOrFalse(
                              value,
                              currentQuestionSection2
                            )
                          "
                        style="
                          font-family: poppinsregular;
                          color: #444444;
                          text-transform: none;
                        "
                      ><v-radio-group style="font-family: poppinssemibold"
                          v-model="value"
                          column
                          
                        >
                          <v-radio label="True" value="True"></v-radio>
                          <v-radio
                            label="False"
                            value="False"
                          ></v-radio> </v-radio-group
                      ></span>
                </v-flex>
              </v-layout>
            </v-card>
            <v-layout wrap py-10 v-if="answerTrueOrFalse">
              <v-flex>
                <v-btn tile color="#3CB043" dark @click="nextQuestionTrueOrFalse"
                  ><span
                    style="text-transform: none; font-family: opensanssemibold"
                    >Save and Continue</span
                  ></v-btn
                >
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout wrap v-if="section3 == 3">
          <v-flex xs12>
            <v-card outlined>
              <v-layout
                wrap
                justify-center
                py-10
                v-if="currentQuestionSection3"
              >
                <v-flex xs12 sm10 text-left>
                  <span style="font-family: poppinsmedium">{{
                    currentQuestionSection3.questionId.question
                  }}</span>
                </v-flex>
                <v-flex xs12 sm10 pt-10 pa-2 text-left>
                      <span
                        style="
                          font-family: poppinsregular;
                          color: #444444;
                          text-transform: none;
                        "
                      ><v-textarea outlined rows="8" v-model="essayAnswer"></v-textarea></span>
                </v-flex>
              </v-layout>
            </v-card>
            <v-layout wrap py-10 v-if="essayAnswer">
              <v-flex>
                <v-btn tile color="#3CB043" dark @click="nextQuestionEssay(essayAnswer,currentQuestionSection3)"
                  ><span
                    style="text-transform: none; font-family: opensanssemibold"
                    >Save and Continue</span
                  ></v-btn
                >
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      snackbar: false,
      msg: null,
      appLoading: false,
      ServerError: false,
      exam: [],
      dirty_form: true,
      showDialog: false,
      // startTime: null,
      // currentTime: 0,
      currentTime: 2700000, // 45 minutes in milliseconds
      question_index: 0,
      question_index2: 0,
      question_index3: 0,
      section: "",
      section2: "",
      section3: "",
      answer: "",
      question: "",
      value:"",
      essayAnswer:"",
      answerTrueOrFalse: "",
      questionTrueOrFalse: "",
      examSection2: [],
      examSection3: [],
      submit:false,
      course:"",
      number:1,
      type:"",
    };
  },
  computed: {
    // formattedTime() {
    //   const hours = Math.floor(this.currentTime / 3600000)
    //     .toString()
    //     .padStart(2, "0");
    //   const minutes = Math.floor((this.currentTime % 3600000) / 60000)
    //     .toString()
    //     .padStart(2, "0");
    //   const seconds = Math.floor((this.currentTime % 60000) / 1000)
    //     .toString()
    //     .padStart(2, "0");
    //   return `${hours}: ${minutes}: ${seconds}`;
    // },
    formattedTime() {
      const remaining = this.currentTime;
      const hours = Math.floor(remaining / 3600000).toString().padStart(2, '0');
      const minutes = Math.floor((remaining % 3600000) / 60000).toString().padStart(2, '0');
      const seconds = Math.floor((remaining % 60000) / 1000).toString().padStart(2, '0');
      return `${hours}:${minutes}:${seconds}`;
    },
    currentQuestion() {
      return this.exam[this.question_index];
    },
    currentQuestionSection2() {
      return this.examSection2[this.question_index2];
    },
    currentQuestionSection3() {
      return this.examSection3[this.question_index3];
    },
  },
  created() {
    // this.startTime = Date.now();
    // this.updateStopwatch();
    this.updateTimer();
  },
  beforeDestroy() {
    // Remove the event listener when the component is destroyed to avoid memory leaks
    window.removeEventListener("contextmenu", this.disableRightClick);
  },
  beforeMount() {
    this.getCourse(),
    this.getData();
    window.addEventListener("contextmenu", this.disableRightClick);
  },
  beforeRouteLeave(to, from, next) {
    // Check if the user is trying to navigate away from the exam page
    if (from.name === "Exam Section") {
      // Display a confirmation dialog
      if (this.submit) {
        next();
      }
      else{
      if (
        window.confirm(
          "Are you sure you want to leave the exam page? Your progress may be lost."
        )
      ) {
        // If the user confirms, allow them to navigate away
             next();
      } else {
        // If the user cancels, prevent navigation
        next(false);
      }
    }
    } else {
      // If leaving from a different route, allow navigation without confirmation
      next();
    }
  },
  methods: {
    disableRightClick(event) {
      event.preventDefault();
    },
    closeDialog() {
      this.showDialog = false;
      this.to = null;
    },
    // updateStopwatch() {
    //   requestAnimationFrame(() => {
    //     this.currentTime = Date.now() - this.startTime;
    //     this.updateStopwatch();
    //   });
    // },
    updateTimer() {
      setInterval(() => {
        if (this.currentTime > 0) {
          this.currentTime -= 1000; // Subtract 1 second
        }
      }, 1000);
    },
    blockContextMenu(event) {
      event.preventDefault();
    },
    getCourse(){
      this.appLoading = true;
      axios({
        url: "/exam/rules",
        method: "POST",
        data: {
          courseId: this.$route.query.courseId,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status) {
          this.appLoading = false;
          this.course = response.data.data;
          }
          else
          this.getDataSection2()
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/exam/start/course/first/section",
        method: "POST",
        data: {
          examId: this.$route.query.id,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status) {
          this.appLoading = false;
          this.exam = response.data.questions;
          this.section = response.data.section;
          }
          else
          this.getDataSection2()
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getDataSection2() {
      this.appLoading = true;
      axios({
        url: "/exam/start/course/second/section",
        method: "POST",
        data: {
          examId: this.$route.query.id,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status) {
          this.appLoading = false;
          this.examSection2 = response.data.questions;
          this.section2 = response.data.section;
          }
          else
          this.getDataSection3()

        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getDataSection3() {
      this.appLoading = true;
      axios({
        url: "/exam/start/course/third/section",
        method: "POST",
        data: {
          examId: this.$route.query.id,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status) {
          this.appLoading = false;
          this.examSection3 = response.data.questions;
          this.section3 = response.data.section;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    storeAnswer(answerId, questionId) {
      this.answer = answerId._id;
      this.question = questionId._id;
    },
    nextQuestionMCQ() {
      axios({
        url: "/exam/answer/submit/first/section",
        method: "POST",
        data: {
          answerId: this.answer,
          questionId: this.question,
        },
        headers: { token: localStorage.getItem("token") },
      })
        .then((response) => {
          if (response.data.status) {
            // this.appLoading = false;
            // this.msg = response.data.msg;
            // this.snackbar = true;
            this.number=response.data.totalQuestions+1
            if(response.data.failed == true)
            {
               this.type="MCQ"
              this.submit=true
              this.$router.push("/examFailed?type=" + this.type);
            }
            if (response.data.isFirstSectionOver == true) {
              this.getDataSection2();
            } else {
              if (this.question_index < this.exam.length - 1) {
                this.question_index++;
                this.answer=""
              }
            }
          } else {
            this.msg = response.data.msg;
            this.snackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    storeAnswerTrueOrFalse(value, id) {
      (this.answerTrueOrFalse = value), (this.questionTrueOrFalse = id._id);
    },
    nextQuestionTrueOrFalse() {
      axios({
        url: "/exam/answer/submit/second/section",
        method: "POST",
        data: {
          booleanValue: this.answerTrueOrFalse,
          questionId: this.questionTrueOrFalse,
        },
        headers: { token: localStorage.getItem("token") },
      })
        .then((response) => {
          if (response.data.status) {
            this.value=""
            this.number=response.data.totalQuestions+1
            if(response.data.failed == true)
            {
              this.type="True/False"
              this.submit=true
              this.$router.push("/examFailed?type=" + this.type);
            }
            if (response.data.isSecondSectionOver == true) {
              this.getDataSection3();
            } else {
              if (this.question_index2 < this.examSection2.length - 1) {
                this.question_index2++;
                this.answerTrueOrFalse=""
              }
            }
          } else {
            this.msg = response.data.msg;
            this.snackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    // storeAnswerEssay(value, id) {
    //   (this.answerTrueOrFalse = value), (this.questionTrueOrFalse = id._id);
    // },
    nextQuestionEssay(ans,id) {
      axios({
        url: "/exam/answer/submit/third/section",
        method: "POST",
        data: {
          answer: ans,
          questionId: id._id,
        },
        headers: { token: localStorage.getItem("token") },
      })
        .then((response) => {
          if (response.data.status) {
            this.essayAnswer=""
            this.number=response.data.totalQuestions+1
            if (response.data.ifExamCompleted == true) {
              this.submit=true
              this.$router.push("/examComplete");
            } else {
              if (this.question_index3 < this.examSection3.length - 1) {
                this.question_index3++;
              }
            }
          } else {
            this.msg = response.data.msg;
            this.snackbar = true;
            if (response.data.ifExamCompleted == true) {
              this.$router.push("/examComplete");
            }
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
.no-copy {
  user-select: none;
}
</style>